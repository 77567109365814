/**
 * Validation front du champ email du petit formulaire de newsletter
 */

function isValid (email) {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
}

let newsletterForm = document.querySelector('#small_newsletter')

if (newsletterForm) {

  newsletterForm.addEventListener('submit', function(e) {
    // Bloque la soumission
    e.preventDefault()
    let email = e.target[0].value

    if (isValid(email)) {
      // Relance l'action
      newsletterForm.submit()
    } else {
      console.log('pas OK')
      // TODO: afficher un message d'erreur : quoi, comment ?
    }
  })
}
